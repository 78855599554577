import React from 'react'
import { Link } from 'react-router-dom'
import { renderName, renderRoles } from '../util/fields'
import { List, Datagrid, TextField, SimpleForm,
  EditButton, DeleteButton, Button,
  Show, TabbedShowLayout, Tab,
  TextInput, NumberInput, Edit, Create, Filter, ReferenceField, ReferenceManyField, FunctionField, required } from 'react-admin'
import paginationProps from '../util/pagination'
import { ConfirmBulkDelete } from '../util/ConfirmBulkDelete'

const validateName = [required()]
const GroupTitle = ({ record }) => {
  return <span>{record ? record.title : 'Customer'}</span>
}

export const GroupList = (props) => (
  <List title="Customers" {...props} bulkActionButtons={<ConfirmBulkDelete />} filters={ <GroupFilter /> } {...paginationProps}>
    <Datagrid rowClick="show">
      <TextField label="Title" source="title" />
      <TextField label="Free Spins per Month" source="numFreeSpins" />
      <TextField label="Cost per Spin" source="costPerSpin" />
      <EditButton />
    </Datagrid>
  </List>
)

export const GroupShow = (props) => (
  <Show title={<GroupTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField label="Title" source="title" />
        <TextField label="Free Spins per Month" source="numFreeSpins" />
        <TextField label="Cost per Spin" source="costPerSpin" />
      </Tab>
      <Tab label="Members">
        <Button
          label="Add Member"
          component={Link}
          to={{
            pathname: '/groupMembership/create',
            state: { group: props.id },
          }}
        />
        <ReferenceManyField addLabel={false} reference="groupMembership" target="group">
          <Datagrid>
            <ReferenceField label="Name" source="user" reference="user">
              <FunctionField render={renderName()} />
            </ReferenceField>
            <FunctionField source="roles" label="Member Role" render={renderRoles()} />
            <EditButton/>
            <DeleteButton basePath={'/group/' + props.id + '/show/1'} undoable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>

)

export const GroupEdit = (props) => (
  <Edit title={'Customer Edit'} undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="Name" source="title" validate={validateName} />
      <NumberInput label="Free Spins per Month" source="numFreeSpins" />
      <NumberInput label="Cost per Spin" source="costPerSpin" />
    </SimpleForm>
  </Edit>
)

export const GroupCreate = (props) => (
  <Create title={'Create Customer'} {...props}>
    <SimpleForm>
      <TextInput label="Name" source="title" validate={validateName} />
    </SimpleForm>
  </Create>
)

export const GroupFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name Search" source="title[$regex]" alwaysOn />
  </Filter>
)

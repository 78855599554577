import React from 'react'
import { renderAddress } from '../util/fields'
import { List, Show, Datagrid, ReferenceField, TextField, NumberField,
  Edit, SimpleForm, TextInput, Create, ReferenceInput, SimpleShowLayout,
  NumberInput, SelectInput, DisabledInput, Filter, FunctionField,
  EditButton, required, minLength, maxLength, number } from 'react-admin'
import paginationProps from '../util/pagination'
import { ConfirmBulkDelete } from '../util/ConfirmBulkDelete'

const validateName = [required()]
// may not want these, just showing what is possible
const validatePhoneNumber = [ number(), minLength(10), maxLength(10) ]
const validateZipCode = [number(), minLength(5), maxLength(5)]

const states = require('../lib/states.json')
const LocationTitle = ({ record }) => {
  return <span>{record ? record.title : 'Location'}</span>
}

export const LocationList = (props) => {
  return (
    <List title="Location List" {...props} bulkActionButtons={<ConfirmBulkDelete />} filters={ <LocationFilter /> } {...paginationProps}>
      <Datagrid rowClick="show">
        <TextField label="Title" source="title" />
        <NumberField label="Free Spins" source="numFreeSpins" />
        <NumberField label="Cost Per Spin" source="costPerSpin" />
        <ReferenceField label="Customer" source="group" reference="group">
          <TextField source="title" />
        </ReferenceField>
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const LocationShow = props => (
  <Show title={<LocationTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <FunctionField style={{ whiteSpace: 'pre-wrap' }} label="Address" render={renderAddress()} />
      <NumberField label="Phone" source="phone" />
      <NumberField label="Free Spins" source="numFreeSpins" />
      <NumberField label="Cost Per Spin" source="costPerSpin" />
      <ReferenceField label="Customer" source="group" reference="group">
        <TextField source="title" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export const LocationEdit = (props) => {
  return (
    <Edit title={'Edit Location'} undoable={false} {...props}>
      <SimpleForm>
        <TextInput label="Title" source="title" validate={validateName} />
        <TextInput label="Street" source="address.thoroughfare" />
        <TextInput label="Apartment" source="address.premise" />
        <TextInput label="City" source="address.locality" />
        <SelectInput label="State" source="address.administrative_area" choices={states} />
        <TextInput label="Zip" source="address.postal_code" validate={validateZipCode} />
        <DisabledInput label="Country" source="address.country" defaultValue="US"/>
        <TextInput label="Phone" source="phone" validate={validatePhoneNumber} />
        <NumberInput label="Number of Free Spins" source="numFreeSpins" />
        <NumberInput label="Cost Per Spin" source="costPerSpin" />
        <ReferenceInput label="Customer" source="group" reference="group">
          <SelectInput optionText="title" optionValue="_id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export const LocationCreate = (props) => {
  return (
    <Create title={'Create Location'} {...props}>
      <SimpleForm>
        <TextInput label="Name" source="title" validate={validateName} />
        <TextInput label="Street" source="address.thoroughfare" />
        <TextInput label="Apartment" source="address.premise" />
        <TextInput label="City" source="address.locality" />
        <SelectInput label="State" source="address.administrative_area" choices={states} />
        <DisabledInput label="Country" source="address.country" defaultValue="US"/>
        <TextInput label="Phone" source="phone" validate={validatePhoneNumber} />
        <NumberInput label="Number of Free Spins" source="numFreeSpins" />
        <NumberInput label="Cost Per Spin" source="costPerSpin" />
        <ReferenceInput label="Customer" source="group" reference="group">
          <SelectInput optionText="title" optionValue="_id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export const LocationFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Customer" source="group" reference="group" alwaysOn>
      <SelectInput optionText="title" optionValue="_id" />
    </ReferenceInput>
  </Filter>
)

export default {
  LocationList,
  LocationEdit,
  LocationCreate,
  LocationFilter
}

/**
 * Provide a default `source` argument for render functions below
 * @param {object} props The props from the render function.
 * @param {object} source The name of the default source property to use.
 */
const defaultSource = (props = {}, defaultSource) => (props.source || defaultSource)

export const renderName = (props) => {
  let source = defaultSource(props, 'name')
  return (record) => (`${record[source].given} ${record[source].family}`)
}

export const renderRoles = (props) => {
  let source = defaultSource(props, 'roles')
  return (record) => (`${record[source].join(', ')}`)
}

export const renderAddress = (props) => {
  let source = defaultSource(props, 'address')
  return (record) => {
    if (record[source]) {
      let address = record[source]
      let area = [[address.locality, address.administrative_area], [address.postal_code]]
        .map(part => part.filter(Boolean).join(', '))
        .filter(str => str.length)
        .join(' ')
      return [
        address.thoroughfare,
        address.premise,
        area
      ].filter(Boolean).join('\n')
    }
  }
}

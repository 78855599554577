// in src/posts.js
import React from 'react'
import { Link } from 'react-router-dom'
import { renderName, renderRoles } from '../util/fields'
import { List, Show, Datagrid, Create, Edit, Filter, SelectInput,
  ReferenceManyField, ReferenceField, TextField, EmailField, NumberField, DateField,
  FunctionField, Button, EditButton, DeleteButton, SingleFieldList, TabbedShowLayout,
  Tab, SimpleForm, TextInput, SelectArrayInput, ChipField } from 'react-admin'
import paginationProps from '../util/pagination'
import { ConfirmBulkDelete } from '../util/ConfirmBulkDelete'

const UserTitle = ({ record }) => {
  return <span>{record ? `${record.name.given} ${record.name.family}` : 'User'}</span>
}

export const UserList = (props) => (
  <List title="User List" {...props} bulkActionButtons={<ConfirmBulkDelete />} filters={ <UserFilter /> } {...paginationProps}>
    <Datagrid rowClick="show">
      <FunctionField source="name" label="Name" render={renderName()} />
      <TextField source="email" label="Email" />
      <DateField source="createdAt" label="Create Date" />
      <NumberField source="status" label="Status" />
      <FunctionField source="roles" label="User Role" render={renderRoles()} />
      <ReferenceManyField label="Customer" reference="groupMembership" target="user">
        <SingleFieldList>
          <ReferenceField label="Customer" source="group" reference="group">
            <ChipField source="title" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
      <EditButton />
    </Datagrid>
  </List>
)

export const UserShow = (props) => (
  <Show title={<UserTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <FunctionField label="Name" render={renderName()} />
        <EmailField label="Email Address" source="email" />
        <DateField label="Created" source="createdAt" />
        <FunctionField source="roles" label="Roles" render={renderRoles()} />
      </Tab>
      <Tab label="Customer">
        <Button
          label="Add to Customer"
          component={Link}
          to={{
            pathname: '/groupMembership/create',
            state: { user: props.id },
          }}
        />
        <ReferenceManyField addLabel={false} reference="groupMembership" target="user">
          <Datagrid>
            <ReferenceField label="Customer" source="group" reference="group">
              <TextField source="title" />
            </ReferenceField>
            <FunctionField source="roles" label="Member Role" render={renderRoles()} />
            <DeleteButton undoable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const UserEdit = (props) => (
  <Edit title={'Edit User'} undoable={false} {...props}>
    <SimpleForm>
      <TextInput label="First Name" source="name.given" />
      <TextInput label="Last Name" source="name.family" />
      <TextInput label="Email Address" source="email" />
      <SelectArrayInput label="User Role" source="roles" choices={[
        { id: 'administrator', name: 'Administrator' },
        { id: 'therapist', name: 'Therapist' },
      ]} />
    </SimpleForm>
  </Edit>
)

export const UserCreate = (props) => (
  <Create title={'Create a New User'} {...props}>
    <SimpleForm>
      <TextInput label="First Name" source="name.given" />
      <TextInput label="Last Name" source="name.family" />
      <TextInput label="Email Address" source="email" />
      <SelectArrayInput label="User Role" source="roles" choices={[
        { id: 'administrator', name: 'Administrator' },
        { id: 'therapist', name: 'Therapist' },
      ]} />
    </SimpleForm>
  </Create>
)

export const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name Search" source="$text[$search]" alwaysOn />
    <TextInput label="Email Search" source="email" alwaysOn />
    <SelectInput source="roles" choices={[
      { id: 'administrator', name: 'Administrator' },
      { id: 'therapist', name: 'Therapist' },
    ]} alwaysOn />
  </Filter>
)

import React from 'react'
import { List, Show, Datagrid, TextField, DateField, ReferenceArrayInput, SelectArrayInput,
  Edit, SimpleForm, ReferenceArrayField, SingleFieldList, ChipField, SimpleShowLayout,
  TextInput, Create, Filter, EditButton } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { DateInput } from 'react-admin-date-inputs'
import paginationProps from '../util/pagination'
import { ConfirmBulkDelete } from '../util/ConfirmBulkDelete'

const AnnouncementTitle = ({ record }) => {
  return <span>{record ? record.title : 'Announcement'}</span>
}

export const AnnouncementList = (props) => {
  return (
    <List title="Announcement List" {...props} bulkActionButtons={<ConfirmBulkDelete />} filters={ <AnnouncementFilter/> } {...paginationProps}>
      <Datagrid rowClick="show">
        <TextField label="Title" source="title" />
        <ReferenceArrayField label="Customer" source="group" reference="group">
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField label="Expire" source="expire" />
        <DateField label="Updated" source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const AnnouncementShow = props => (
  <Show title={<AnnouncementTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Title" source="title" />
      <ReferenceArrayField label="Customer" source="group" reference="group">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField label="Expire" source="expire" />
      <DateField label="Updated" source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)

export const AnnouncementEdit = (props) => {
  return (
    <Edit title={'Edit Announcement'} undoable={false} {...props}>
      <SimpleForm>
        <TextInput label="Title" source="title" />
        <RichTextInput label="Description" source="description" />
        <DateInput label="Expiry" source="expire" options={{ clearable: true }} />
        <ReferenceArrayInput label="Customer" source="group" reference="group" perPage={0} sort={{ field: 'title', order: 'ASC' }} >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export const AnnouncementCreate = (props) => {
  return (
    <Create title={'Create Announcement'} {...props}>
      <SimpleForm>
        <TextInput label="Title" source="title" />
        <RichTextInput label="Description" source="description" />
        <DateInput label="Expiry" source="expire" options={{ clearable: true }} />
        <ReferenceArrayInput label="Customer" source="group" reference="group" perPage={0} sort={{ field: 'title', order: 'ASC' }} >
          <SelectArrayInput optionText="title" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  )
}

export const AnnouncementFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Title Search" source="title[$regex]" alwaysOn />
  </Filter>
)

export default {
  AnnouncementList,
  AnnouncementEdit,
  AnnouncementCreate,
  AnnouncementFilter,
}

import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import App from './components/App'
import './styles/index.scss'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#19324a',
      700: '#77ba3e'
    },
    secondary: {
      main: '#77ba3e',
    },
  },
  typography: {
    fontFamily: ['Cairo']
  }
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
)

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS, AUTH_ERROR } from 'react-admin'
import app from 'common/client/app'

const authProvider = async (type, params) => {
  switch (type) {
    case AUTH_CHECK: {
      return app.authenticate()
    }
    case AUTH_LOGIN: {
      return app.authenticate()
    }
    case AUTH_LOGOUT: {
      return app.logout()
    }
    case AUTH_GET_PERMISSIONS: {
      let token = await app.authenticate()
      let payload = await app.passport.verifyJWT(token.accessToken)
      let user = await app.service('user').get(payload.userId)
      return user.isVerified && user.roles.includes('administrator')
    }
    case AUTH_ERROR: {
      const { status } = params
      switch (status) {
        case 401: {
          throw new Error('Forbidden')
        }
        default: {
          return
        }
      }
    }
    default: {
      throw new Error()
    }
  }
}

export default authProvider

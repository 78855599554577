import React from 'react'
import { renderName } from '../util/fields'
import { List, Edit, Datagrid, ReferenceField, TextField, DateField, FunctionField,
  Create, SelectInput, ReferenceInput, Show, SimpleShowLayout, EmailField, NumberField,
  TextInput, SimpleForm, Filter, EditButton, NumberInput } from 'react-admin'
import { DateInput } from 'react-admin-date-inputs'
import paginationProps from '../util/pagination'
import { gender } from 'common/variables'
import { ConfirmBulkDelete } from '../util/ConfirmBulkDelete'

const PatientTitle = ({ record }) => {
  return <span>{record ? `${record.name.given} ${record.name.family}` : 'Patient'}</span>
}

export const PatientList = (props) => (
  <List title="Patient List" {...props} bulkActionButtons={<ConfirmBulkDelete />} filters={ <PatientFilter /> } {...paginationProps}>
    <Datagrid rowClick="show">
      <TextField label="Patient ID" source="patientID" />
      <FunctionField label="Name" source="name" render={renderName()} />
      <DateField label="Date of Birth" source="dob" />
      <TextField label="Email" source="email" />
      <TextField label="Gender" source="gender" />
      <ReferenceField label="Customer" source="group" reference="group">
        <TextField source="title" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export const PatientShow = props => (
  <Show title={<PatientTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Patient ID" source="patientID" />
      <FunctionField label="Name" source="name" render={renderName()} />
      <TextField label="Nickname" source="nickname" />
      <DateField label="Date of Birth" source="dob" />
      <EmailField label="Email" source="email" />
      <NumberField label="Phone" source="phone" />
      <TextField label="Gender" source="gender" />
      <NumberField label="Height" source="height" />
      <NumberField label="Weight" source="weight" />
      <ReferenceField label="Customer" source="group" reference="group">
        <TextField source="title" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)

export const PatientEdit = (props) => (
  <Edit title={'Edit Patient Information'} undoable={false} {...props}>
    <SimpleForm>
      <TextInput source="name.given" label="First Name" />
      <TextInput source="name.family" label="Last Name" />
      <DateInput source="dob" label="Birthday" />
      <TextInput source="email" label="Email" />
      <TextInput source="patientID" label="Patient ID" />
      <TextInput source="phone" label="Phone" />
      <SelectInput source="gender" label="Gender" choices={gender}/>
      <NumberInput source="height" label="Height"/>
      <NumberInput source="weight" label="Weight"/>
      <TextInput source="nickname" label="Nickname" />
      <ReferenceInput label="Customer" source="group" reference="group">
        <SelectInput optionText="title" optionValue="_id" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const PatientCreate = (props) => (
  <Create title={'Create Patient'} {...props}>
    <SimpleForm>
      <TextInput source="name.given" label="First Name" />
      <TextInput source="name.family" label="Last Name" />
      <DateInput source="dob" label="Birthday" />
      <TextInput source="email" label="Email" />
      <TextInput source="patientID" label="Patient ID" />
      <TextInput source="phone" label="Phone" />
      <SelectInput source="gender" label="Gender" choices={gender}/>
      <NumberInput source="height" label="Height"/>
      <NumberInput source="weight" label="Weight"/>
      <TextInput source="nickname" label="Nickname" />
      <ReferenceInput label="Customer" source="group" reference="group">
        <SelectInput optionText="title" optionValue="_id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const PatientFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name Search" source="$text[$search]" alwaysOn />
    <TextInput label="Email Search" source="email" alwaysOn />
    <ReferenceInput label="Customer" source="group" reference="group" alwaysOn>
      <SelectInput optionText="title" optionValue="_id" />
    </ReferenceInput>
  </Filter>
)

export default {
  PatientList,
  PatientEdit,
  PatientCreate,
  PatientFilter,
}

import React from 'react'
import { List, Show, Datagrid, ReferenceField, TextField, BooleanField, Edit,
  ReferenceInput, SimpleForm, TextInput, Filter, Create, SimpleShowLayout,
  SelectInput, DisabledInput, EditButton } from 'react-admin'
import paginationProps from '../util/pagination'
import { ConfirmBulkDelete } from '../util/ConfirmBulkDelete'

const MachineTitle = ({ record }) => {
  return <span>{record ? record.name : 'Machine'}</span>
}

export const MachineList = (props) => {
  return (
    <List title="Machine List" {...props} bulkActionButtons={<ConfirmBulkDelete />} filters={<MachineFilter />} {...paginationProps}>
      <Datagrid rowClick="show">
        <TextField source="name" label="Name" />
        <BooleanField source="connected" label="Connected" />
        <TextField source="deviceId" label="Device ID" />
        <ReferenceField label="Customer" source="group" reference="group">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField label="Location" source="location" reference="location">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="currentAngle" label="Current Angle" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const MachineShow = props => (
  <Show title={<MachineTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Name" />
      <BooleanField source="connected" label="Connected" />
      <TextField source="deviceId" label="Device ID" />
      <ReferenceField label="Customer" source="group" reference="group">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Location" source="location" reference="location">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="currentAngle" label="Current Angle" />
    </SimpleShowLayout>
  </Show>
)

export const MachineEdit = (props) => (
  <Edit undoable={false} title={'Edit Machine'} {...props}>
    <SimpleForm>
      <DisabledInput source="_id" label="ID" />
      <TextInput source="name" label="Name"/>
      <TextInput source="deviceId" label="Device ID" />
      <TextInput source="serial" label="Serial Number" />
      <ReferenceInput label="Customer" source="group" reference="group">
        <SelectInput optionText="title" optionValue="_id" />
      </ReferenceInput>
      <ReferenceInput label="Location" source="location" reference="location">
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const MachineCreate = (props) => (
  <Create title="Create Machine" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Name" />
      <TextInput source="deviceId" label="Device ID" />
      <TextInput source="serial" label="Serial Number" />
      <ReferenceInput label="Customer" source="group" reference="group">
        <SelectInput optionText="title" optionValue="_id" />
      </ReferenceInput>
      <ReferenceInput label="Location" source="location" reference="location">
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const MachineFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Customer" source="group" reference="group" alwaysOn>
      <SelectInput optionText="title" optionValue="_id" />
    </ReferenceInput>
    <TextInput label="Device ID" source="deviceId" alwaysOn />
  </Filter>
)

export default {
  MachineList,
  MachineEdit,
  MachineCreate,
  MachineFilter,
}

import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import '../styles/index.scss'
import '../styles/login.scss'

class Login extends Component {
  render () {
    // If we are using a remote API, redirect back here after login
    let url = `${process.env.REACT_APP_LOGIN_URL}user/login?destination=${window.location.href}`

    return (
      <div className="page login">
        <footer>
          <Button variant="contained" color="secondary" className="button" href={url}>Login</Button>
        </footer>
      </div>
    )
  }
}

export default Login
